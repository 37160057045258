/* @override 
	https://hermosawavephotography.com/assets/css/styles.css */

/* Typekit Asian Fonts */
/*
font-family: ryo-gothic-plusn, sans-serif; (200, 300, 700)
myriad-pro-semiextended
*/

@charset "UTF-8";
/* Global Reset & Standards ---------------------- */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  /*  font-family: proxima-nova, sans-serif;  */
   font-family: ryo-gothic-plusn, sans-serif; 
    background-color: #202020;
	margin: 0 0 10px 0;
    padding: 0 0 20px 0;
	color: #a6a6a6;
 	width: 100%;
}


.mainbody {
	background-color: #000;
	margin: 0 5% 20px 5%;
	padding: 0;
	max-width: 2048px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
	border: 1px solid #000;
	border-top: none;
}

.fullwidth {
    max-width: none;
}

.topbody {
	padding: 0 3% 0 3%;   
}


.mainimage {
 /*   margin: 0 0 10px 0; */
    margin: 0 0 0 0;
    padding: 0;
}


.mainimageleft /* back navi */ {
	width: 30%;
	height: 60%;
	z-index: 100;
	position: absolute;
}

.mainimageleft a {
    cursor: w-resize;
}

.mainimageleft .clearpixel {
    width: 100%;
    height: 100%;
}

p {
    font-weight: 300;
    font-style: normal;
    font-size: 12pt;
    line-height: 18pt;
    padding-bottom: 15px;
    margin: 0;
}

h1, h2, h3 {
    font-weight: 200;
    margin: 10px 0 0 0;
}

h4, h5, h6 {
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 10px 0 0 0;
}

h1 {
    font-size: 26pt;
    line-height: 34pt;
    padding: 0;
    margin: 10px 0 20px 0;
  
}

h2 {
    font-size: 22pt;
    line-height: 26pt;
    padding: 20px 0 10px 0;
}

h3 {
    font-size: 16pt;
    line-height: 22pt;
    font-weight: 300;
    letter-spacing: 0;
    /* margin-bottom: 10px; 
    padding: 20px 0 10px 0; */
    padding: 10px 0 5px 0;

}

h4 {
    font-size: 10pt;
    line-height: 18pt;
    font-weight: 300;
}


a {
    color: #fff;
    text-decoration: none;
    margin: 0 1px;
}

a:hover {
		text-decoration: none;
		color: cornflowerblue ;
}


img {
    width: 100%;
    border-radius: 6px;
}

nav {
    font-size: 10pt;
    line-height: 20pt;
    font-weight: 300;
    text-transform: uppercase;
	padding: 20px 3% 20px 3%;
	letter-spacing: 1px;
}

.navitem {
    display: inline;
}

nav a {
		text-decoration: none;
		color: #fff;
}

nav a:hover, nav a .hwp:hover {
        color: cornflowerblue;
 		text-decoration: none;

}

nav .current {
    font-weight: 700;
}

nav a .hwp {
    color:darkgoldenrod;
   
}

nav.eknav {
  padding: 0;
}

#img1 {
	border-radius: 8px;
	width: 100%;
    cursor:e-resize;
    margin: 0;
    padding: 0;
}

h3.listhead {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0 0 0 20px;
}

li {
    font-weight: 300;
    font-style: normal;
    font-size: 12pt;
    line-height: 15pt;
    padding-bottom: 10px;
    margin: 0;
}

p.subtitle {
    font-weight: 300;
    font-size: 10pt;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}


.mobile  /* show on mobile */ {
    display: none;
}

.mobileinline /* show on mobile display inline */ {
  display: none;
}

/* reformatting for vertical images “aspect: vertical” */

.vertical .mainimage { 
    width: 54%;
    padding: 0 5% 0 0; 
    float: left;
}

.vertical .description {
    float: left;
    width: 45%;
}

/* Thumbnails */

.pagenumber {
    font-size: 12pt;
}

.thumbnailgroup {
    margin-top: 10px;
}


.thumbnailimage {
    max-height: 240px;
    overflow: hidden;
    margin-bottom: 5px;
}

.thumbnail {
	display: inline-block;
	width: 23%;
	overflow: hidden;
	margin: 10px 1% 20px 5px;
	padding: 1px;
	text-align: center;
	font-size: 9pt;
	line-height: 12pt;
    font-weight: 300;
	vertical-align: top;
}

.thumbnail.tag {
    text-align: left;   
     background-color: #222;
    padding: 10px 10px 10px 10px;
 	margin: 10px 1% 30px 0;
   border-radius: 8px;
}

.thumbnail.tag h3 {
    margin: 0;
    padding: 0;
    border-bottom: solid 2px #000;
}

.thumbnail.tag ul {   
    margin: 0;
    list-style: none;
    height: 200px;
    overflow: auto;
     padding: 10px;
}

.thumbnail.tag li {
  font-size: 8pt;
  line-height: 10pt;
  padding-bottom: 5px;
}

.thumbnail img {
	width: 99%;
	border: 1px solid #000;
}

.thumbnail a {
	color:  #ccc;
}

.thumbnail a:hover {
	color: cornflowerblue;
	text-decoration: none;
}

.thumbnail a:hover img{
	border: 1px solid white;
}



.thumbnailgroup.float {
    display: inline-block;
    width: 23%;
    margin: 0 0.75%;
}


.thumbnail.float {    
    width: 100%;
}


.buy_button a {
 width: 220px;
  display: inline-block;
  border: 0;
  border-radius: 0.25rem;
  background: green;
  color: white;
  text-align: center; 
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  padding: 8px;
  margin: 0 20px 8px 0;
   cursor: pointer; 
  
}

 .buy_button a:hover {
    background: white;
    color: green;
}



/*　Subimages (secondary images on main photo page) */

.subimagegroup {
    text-align: center;
    margin: 10px 0;
}


.subimage {
	display: inline-block;
	width: 45%;
	overflow: hidden;
	margin: 10px 2% 30px 2%;
	padding: 1px;
	text-align: center;
	font-size: 9pt;
	line-height: 14pt;
    font-weight: 300;
	vertical-align: middle;
}

.left {
  text-align: left;   
}

/* exif */
#exif {
    margin: 10px;
    font-size: 8pt;
    line-height: 12pt;
    font-weight: 300;
    color: #888;
}

.tags {
    color: #666;
    font-size: 8pt;
    line-height: 12pt;
    font-weight: 300;
    margin: 0;
   
}

#tagsection {
      display: none; 
}


/* Embedded Video */
.videocontainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Post Navi */
.post-nav {
    clear: both;
  	text-align: center;
  	padding: 8px 0 8px 0;
  	margin: 20px 0 0 0;
  	background-color: #333; 
    font-size: 9pt;
	  line-height: 12pt;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 300;
    border-top: 1px #000 solid;
    
}
.post-nav div {
	display: inline;
	padding: 0 3px;
}

.post-nav a {
	color: #aaa;
}

.post-nav a:hover {
	color: cornflowerblue;
}



.thumbnavleft {
    margin-right: 1%;
}
.thumbnavright {
    margin-left: 1%;
}


/* // Footer stuff // */
footer {
    clear: both;
    text-transform: uppercase;
    letter-spacing: 1px;
	
    margin: 0;
    padding: 20px 3%;
    font-size: 8pt;
    line-height: 12pt;
    color: #666;
    text-align: center;
    border-top: 1px #444 solid;
    
    // 2022 flexbox addl //
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
    justify-content: space-between;
}

.footer1,
.footer2,
.footer3 {
   // display: inline-block;
    text-align: left;
    vertical-align: top;
  //   margin: 0 5%;
}

.footer1, .footer2, .footer3 {
    line-height: 14pt;
}

footer nav {
    padding: 0;
}

footer .navitem {
    display: block;
   font-size: 8pt;
    line-height: 12pt;
    color: #666;
}

footer a {
    color: #ccc;
    /* #ccc is good too */
}

footer img.calendar_thumb {
  //width: 50%;
  max-width: 220px;
  padding: 10px 0; 
}

/* Ko-Fi Button */
.ko-fi-button {
 width: 240px;
  max-width: 100%;
  margin: 10px 0;
}

/* // front page divs // */
#frontpageleft {
    width: 48%;
    float: left;
    margin-top: 10px;
}

#frontpageright {
    width: 48%;
    float: right;
    margin-top: 10px;
}

#frontpageleft .mainimage img,
#frontpageright .mainimage img {
    cursor: pointer;
}


.headshot {
    float: right;
    width: 35%;
    max-width: 360px;
    margin: 0 0 20px 20px;
}

.bookfloatleft {
  float: left;
  width: 120px;
  padding: 0 10px 10px 0;
  border-radius: 6px;
}

/* /////////////  PICTURECODE STYLES  /////////////// */
.picturecode img {
    float: right;
    width: 50%;
    border-radius: 0;
    margin: 4px 0 4px 4px;
}

.picturecode form {
    text-align: center;
    width: 40%;
    margin: 0 5px;
    padding: 0 20px 20px 20px;
    border: 1px solid orange;
    border-radius: 6px;
}

/* ///////////// /ARCHITECTURE STYLES  ///////////// */

ul.photogallery {
    width: 100%;
    padding: 0 0 0 10px;
    list-style: none;
    font-size: 10pt;
    line-height: 12pt;
}

.photogallery li {
    margin: 0 2% 30px 0;
    width: 47%;
    display: inline-block;
    vertical-align: top;
}

.photogallery img {
    width: 100%;
    border-radius: 5px;
    margin: 0 0 5px 0;
}



/* ///////////// BILINGUAL FORMATTING ///////////// */
/* basically only for standard horizontal images, will need to remove for phones */

/* ///// BILINGUAL NAVIGATION //// */

.jp_nav {
    display: none;
}


.jp {
    font-size: 11pt;
    line-height: 18pt;
}

.title_jp { /* taken from h2 styles */
  display: block;
  font-weight: 200;
  font-size: 16pt;
  line-height: 24pt;
  padding: 10px 0 0px 0;
}

h3.jp {
    font-size: 16pt;
    line-height: 20pt;
    margin-bottom: 10px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 0;
}

h3.en {
    font-size: 16pt;
    line-height: 20pt;
    margin-bottom: 10px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 0;
}

/* .jp {  to remove jpnz text 
  display: none;
}
*/


 /* ///// FORM STYLES ////// */
.contact-form {
    margin: 5px;
    padding: 20px;
    border: 1px solid #444444;
    border-radius: 4px;
}

.form-label {
    width:100px;
    position: absolute;
    text-align: right;
}
.form-entry input, 
.form-entry button {
    margin-left: 110px;
    width: 200px;    
 }
.form-entry textarea {
    margin-left: 110px;
    width: 200px;    
    height: 100px;
 }
/* Are we using the above styles anywhere? Picturecode maybe? */

/* // 2025 Store Select Page // */
#printselect {
  // overall print selector form //
}

img.matted {
  // adds white border //
  border: 20px white solid;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.printsize {
}

.printoptions {
  border: 0;
  margin: 0;
  padding: 0;
}

.printselect {
  padding: 50px 0 0 0;
}

.printoptions input:checked {
  color: #fff;
}

.printoptions ul {
  margin: 20px 0 10px 0;
  padding: 0;
  list-style-type: none; /* Remove bullets */
}
.printoptions li { 
  margin: 0 0 0 10px;
  font-size: 10pt;
}

.printbutton {
  margin: 20px 0;
}


/* ///////////// RESPONSIVE OVERRIDES  ///////////// */
/* default wide screen */

/* smaller monitor and ipad */

@media only screen and (max-width: 1032px) {

    .mainbody {
        margin: 0 30px 10px 30px;
        /* padding: 0 20px 0 20px; */
        padding: 0;
        max-width: 1024px;
        display: block;
     }
    
    .topbody {
	padding: 0 4% 0 4%;   
    }
    
    .mainimageleft /* back navi */ {
        height: 40%;      
    }
    
    
    .post-nav {
        /* margin: 6px -20px 0 -20px; */
        padding: 5px 10px 5px 10px;
        letter-spacing: 0;
        
    
    }
    
    .post-nav div {
	display: inline;
	padding: 0 7px;
}
    
    nav {
 	  padding: 15px 3% 14px 3%;
    }

    .thumbnavleft {
        margin-right: 0;
    }
    .thumbnavright {
        margin-left: 0;
    }
    
   /* 2022 flexbox  
    .footer1,
    .footer2,
    .footer3 {
         margin: 0 2%;
    }
    */
    
    .thumbnail {
        width: 31%; 
      }
    
    .thumbnailgroup.float {
     width: 31%;   
    }
    
    .midsize /* hide on midsize */ {
        display: none;
    }
}

/* iPhone horizontal and vertical? */
@media only screen and (max-width : 812px)  {

    body {
         margin:  0; 
         padding: 0; 
    }
    
    .mainbody {
         /* margin:  0; 
         padding: 0; */
         padding: 0 20px 0 20px; 
        margin: 0;
       max-width: 780px;
        border: none;
     }
    
    .topbody {
        margin: 0;
        padding: 0 20px ;        
    }

 /*  (moved to vertical below)
    .vertical .mainimage { 
    width: 100%;
    padding: 0 0 0 0; 
    float: none;
}
    
    .vertical .description {
    float: none;
    width: 100%;
}
    */
    
    nav {
 	  padding: 10px 20px 10px 20px;
      letter-spacing: 1px;  
        font-size: 8pt;
    }
    
 
    .post-nav {
        margin: 10px 0 0 0;
        padding: 6px 5px 2px 5px; 
        
        letter-spacing: 0px;  
        font-size: 8pt;
	    line-height: 14pt;
    }
    
    .thumbnailimage {
        max-height: 200px;
        overflow: hidden;
        margin-bottom: 5px;
    }
    
    .thumbnavleft {
        margin-right: 0;
        padding-right: 4%;
}
    .thumbnavright {
        margin-left: 0;
        padding-left: 4%;
}
    
    footer {
 	  padding: 10px 0;
          margin: 0;
     }
    
     
   
    
    h2 {
        font-size: 16pt;
        line-height: 20pt;
        /* font-size: 16pt;
        line-height: 18pt;
        font-weight: 700; */
    }
    
    h3 {
        font-size: 14pt;
        line-height: 18pt;
    }
    
    h4 {
        font-size: 10pt;
        line-height: 12pt;
    }
    
    p {
        font-size: 10pt;
        line-height: 16pt;
    }
    
 /*
    .jp {
        line-height: 16pt;
        font-size: 9pt;
    }
    
    .title_jp { 
        font-size: 15pt;
        line-height: 20pt;
       }
       */

    p.subtitle {
        font-size: 8pt;
        line-height: 10pt;
       letter-spacing: 1px;
        padding: 0;
        margin: 0;
     }
    
    .midsize /* hide on tablets */ {
        display: none;
    }
    
    
    .picturecode img {
        float: none;
        width: 100%;
        max-width:100%;

    }

    .picturecode form {
        text-align: center;
        width: 100%;
        margin: 0 5px;
    }

}

/* iPhone vertical only */
@media only screen and (max-width : 812px) and (orientation: portrait) { 
 
    .mainbody {
        margin: 0;
        padding: 0;
        background-color: #000;
     }
    
    .topbody {
        padding: 0 10px ;
    }
    
    .mainimageleft /* back navi */ {
        height: 75%;

    }
    
      .vertical .mainimage { 
    width: 100%;
    padding: 0 0 0 0; 
    float: none;
}
    
    .vertical .description {
    float: none;
    width: 100%;
}
    
    nav {
 	  padding: 5px 10px 10px 10px;
     
    }
 
    .post-nav {
        margin: 0;
       padding: 6px 10px;
    }
    .post-nav div {
        padding: 0 4px;
}
    
    footer {
        padding: 10px;
        margin: 0;
     }
    /* 2022 flexbox 
     
    .footer1,
    .footer2,
    .footer3 {
        display: block;
        text-align: left;
        vertical-align: top;
        margin: 20px 0 ;
        line-height: 16pt;
    }
    */
    
    footer img._thumb {
      //width: 50%;
      max-width: 200px;
      padding: 10px 0;
    }
    
    footer .navitem, footer br a {
      line-height: 18pt;
    }
    


    
    .midsize /* hide on mobile */ {
        display: none;
    }
    
    .mobileyes /* hide on mobile */ {
        display: none;
    }
    
    .mobile  /* show on mobile */ {
        display: block;
        font-size: 9pt;
        /*color: #888;*/
    }

    .mobileinline /* show on mobile display inline */ {
      display: inline;
    }

    .thumbnail {
        width: 47%; 
        margin: 0 1px 20px 1px;

        }
    
    .thumbnailgroup.float {   
        width: 47%;
    }
    

        /*　Subimages */

    .subimagegroup {
        text-align: center;
    }


    .subimage {
        display: inline-block;
        width: 90%;
        overflow: hidden;
        margin: 10px 2% 30px 2%;
        padding: 1px;
        text-align: center;
        font-size: 9pt;
        line-height: 12pt;
        font-weight: 300;
        vertical-align: middle;
    }

    
    .photogallery li {
        margin: 0 0 20px 0;
        width: 97%;
        display: block;
        vertical-align: top;
    }
    
        /* // front page divs // */
    #frontpageleft, #frontpageright {
        width: 100%;
        float: none;
    }
    
}
